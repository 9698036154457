import { get, postFormData, putFormData, del } from "javascripts/lib/request"

export default id => ({
  show: supportingResourceId =>
    get(`/tasks/${id}/supporting_resources/${supportingResourceId}`)
      .then(response => response.json()),

  create: supportingResource =>
    postFormData(`/tasks/${id}/supporting_resources`, supportingResource)
      .then(response => response.json()),

  update: (supportingResourceId, supportingResource) =>
    putFormData(`/tasks/${id}/supporting_resources/${supportingResourceId}`, supportingResource)
      .then(response => response.json()),

  destroy: supportingResourceId =>
    del(`/tasks/${id}/supporting_resources/${supportingResourceId}`)
})

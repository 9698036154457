import ReactDOM from "react-dom"
import { Controller } from "stimulus"

export default class extends Controller {
  get mountPoint() {
    return this.element
  }

  get component() {
    throw "You must override ReactComponentController.component() and return a React component"
  }

  get props() {
    this._props = this._props || JSON.parse(this.data.get("props"))
    return this._props
  }

  connect() {
    ReactDOM.render(this.component, this.mountPoint)
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.mountPoint)
  }
}

import React from 'react'

const Header = ({props}) => {
  return (
    <>
      <h3> {props.question} </h3>
    </>
  )
}

export default Header

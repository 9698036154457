import React, { useState, useRef, useEffect } from "react";
import { snakeCase, isEmpty } from "lodash";
import i18next from "i18next";

import queryString from "query-string";
import Select from "react-select";

import Option from "./components/option";
import { get, post, handleFailedRequest } from "javascripts/lib/request";

const SelectInput = ({
  name,
  label,
  url,
  urlParams = {},
  createUrl,
  createWithModal,
  className,
  isMulti = false,
  isClearable = true,
  cacheOptions = true,
  maxMenuHeight = 300,
  filterLoadedOptions = (options) => options,
  ...props
}) => {
  let SelectComponent;
  let modalWindow;

  SelectComponent = Select;

  const [options, setOptions] = useState(props.options || []);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(props.isDisabled);
  const [moreOptionsAvailable, setMoreOptionsAvailable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false); // Needed this because `isLoading` is bugged
  const [showingDefaultOptions, setShowingDefaultOptions] = useState(true);
  const [menuPlacement, setMenuPlacement] = useState("bottom");

  const selectEl = useRef(null);
  const selectRef = useRef(null);
  const defaultOptionsLoaded = useRef(false);
  const labelId = `${snakeCase(name)}_label`;

  const onLabelClick = () => selectRef.current.focus();

  const loadNextPage = () => {
    setIsLoading(true);

    get(url, { ...urlParams, page: currentPage })
      .then((response) => response.json())
      .then(({ options, moreOptionsAvailable }) => {
        defaultOptionsLoaded.current = true;

        setIsLoading(false);
        setIsLoadingMore(false);
        setCurrentPage(currentPage + 1);
        setMoreOptionsAvailable(moreOptionsAvailable);

        setDefaultOptions(filterLoadedOptions([...defaultOptions, ...options]));
      })
      .catch(
        handleFailedRequest(() =>
          alert(i18next.t("select_input.load_options_error"))
        )
      );
  };

  const onFocus = () => {
    setShowingDefaultOptions(true);
    if (defaultOptionsLoaded.current) return;

    loadNextPage();
  };

  const setNewOption = (newOption) => {
    setIsLoading(false);
    setIsDisabled(false);

    if (!url) {
      setOptions([...options, newOption]);
    }

    if (isMulti) {
      props.onChange([...props.value, newOption]);
    } else {
      props.onChange(newOption);
    }
  };

  const createFromModal = (saveUrl) => {
    post(saveUrl, modalWindow.formData())
      .then((response) => response.json())
      .then(({ result, errors }) => {
        if (result) {
          setNewOption(result);

          modalWindow.hide();
        } else {
          modalWindow.flash("failure", i18next.t("create_from_modal.failed"));
          modalWindow.applyErrors(errors);
        }
      })
      .catch(
        handleFailedRequest(() => alert(i18next.t("create_from_modal.error")))
      );
  };

  const onCreateOption = (inputValue, createOptions = {}) => {
    setIsLoading(true);
    setIsDisabled(true);

    if (createWithModal) {
      const { heading, clientId, formUrl, saveUrl } = createOptions;

      modalWindow.show({
        mode: "edit",
        heading: heading,
        url: `${formUrl}?${queryString.stringify({
          input_value: inputValue,
          client_id: clientId,
        })}`,
        onSubmit: () => {
          createFromModal(saveUrl);
        },
        onCancel: () => {
          setIsLoading(false);
          setIsDisabled(false);
        },
      });
    } else {
      post(createUrl, { input_value: inputValue })
        .then((response) => response.json())
        .then((newOption) => setNewOption(newOption))
        .catch(
          handleFailedRequest(() =>
            alert(i18next.t("select_input.create_option_error"))
          )
        );
    }
  };

  const onInputChange = (inputValue) => {
    setShowingDefaultOptions(isEmpty(inputValue));
  };

  const onLoadMore = () => {
    setIsLoadingMore(true);

    loadNextPage();
  };

  const calculateMenuPlacement = () => {
    const { top, bottom } = selectEl.current.getBoundingClientRect();

    const spaceBelow = window.innerHeight - bottom;
    const spaceAbove = window.innerHeight - top;

    if (spaceBelow > maxMenuHeight + 10) {
      // Add an extra 10 pixels to account for margin
      return "bottom";
    }

    if (spaceAbove > spaceBelow) {
      return "top";
    } else {
      return "bottom";
    }
  };

  const onMenuOpen = () => setMenuPlacement(calculateMenuPlacement());

  useEffect(() => {
    defaultOptionsLoaded.current = false;
    setDefaultOptions([]);
    setShowingDefaultOptions(false);
  }, [cacheOptions]);

  useEffect(() => {
    setIsDisabled(props.isDisabled);
  }, [props.isDisabled]);

  return (
    <React.Fragment>
      {label && (
        <label id={labelId} className="form__label" onClick={onLabelClick}>
          {label}
        </label>
      )}
      <div ref={selectEl}>
        <SelectComponent
          {...props}
          aria-labelledby={label && labelId}
          className={`select-input ${className ? className : ""}`}
          classNamePrefix="select-input"
          placeholder={
            createUrl || createWithModal
              ? i18next.t("select_input.select_or_type")
              : i18next.t("select_input.select")
          }
          ref={selectRef}
          options={options}
          isMulti={isMulti}
          isClearable={isClearable}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          isDisabled={isDisabled}
          onFocus={url ? onFocus : null}
          onCreateOption={onCreateOption}
          onLoadMore={onLoadMore}
          onInputChange={onInputChange}
          onMenuOpen={onMenuOpen}
          cacheOptions={cacheOptions}
          defaultOptions={defaultOptions}
          moreOptionsAvailable={moreOptionsAvailable}
          showingDefaultOptions={showingDefaultOptions}
          maxMenuHeight={maxMenuHeight}
          menuPortalTarget={document.body}
          multiCreateOptions={createWithModal || []}
          menuPlacement={menuPlacement}
          closeMenuOnScroll={(event) =>
            event.target == document ||
            !(
              event.target.className &&
              event.target.className.includes("select-input")
            )
          }
          menuShouldScrollIntoView={false}
          loadOptions={(inputValue) =>
            get(url, { ...urlParams, query: inputValue })
              .then((response) => response.json())
              .then(({ options }) => filterLoadedOptions(options))
              .catch(
                handleFailedRequest(() =>
                  alert(i18next.t("select_input.load_options_error"))
                )
              )
          }
          components={{
            Option,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default SelectInput;

export default (state = {}, action = {}) => {
  switch (action.type) {
    case "UPDATE_ATTRIBUTES":
      return {
        ...state,
        ...action.payload
      }
    case "ADD_ITEM":
      return {
        ...state,
        [action.scope]: [
          ...state[action.scope],
          action.payload
        ]
      }
    case "UPDATE_ITEM":
      return {
        ...state,
        [action.scope]: state[action.scope].map(
          item => item.id === action.id ? { ...item, ...action.payload } : item
        )
      }
    default:
      return state
  }
}

import React from "react"
import { connect, getIn } from "formik"

import DateField from "components/date_field"

const EditableAreaDateField = ({ formik, ...props }) => {
  const value = getIn(formik.values, props.name)
  const error = getIn(formik.errors, props.name)

  const { setFieldValue, isSubmitting } = formik

  // FIXME: when Flatpickr updates the virtual dom, get this tested
  // https://github.com/haoxins/react-flatpickr/issues/92 being watched
  /* istanbul ignore next */
  return (
    <DateField
      onChange={(_selectedDates, _dateStr, instance) => {
        setFieldValue(props.name, instance._input.value)
        instance._selDateStr = instance._input.value
      }}
      value={value}
      error={error}
      disabled={isSubmitting}
      {...props}
    />
  )
}

export default connect(EditableAreaDateField)

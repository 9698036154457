import React from "react"

const PageRegion = ({ heading, children }) => (
  <div className="page-region" id={heading.replace(/\s/gi, "-").toLowerCase()}>
    <div className="page-region__header">
      <h3 className="page-region__heading">{heading}</h3>
    </div>

    <div className="page-region__content">
      {children}
    </div>
  </div>
)

export default PageRegion

import React from "react"

const TextButton = ({ label, icon, ...props }) => (
  <button className="text-button" {...props}>
    {icon && <i className={`zmdi zmdi-${icon}`}></i>}
    {label}
  </button>
)

export default TextButton

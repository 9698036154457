import React from "react"
import classnames from "classnames"
import { components } from "react-select"
import { compact } from "lodash"

const buildLabel = (label, prefix, suffix) => compact([
  prefix && `${prefix}:`,
  label,
  suffix && `(${suffix})`
]).join(" ")

const Option = ({
  label,
  data: {
    acronym = null,
    prefix = null,
    suffix = null,
    highlight = false
  },
  ...props
}) => (
  <components.Option {...props}>
    <span className={classnames("select-input__option__label", {
      "select-input__option__label--highlighted": highlight
    })}>
      {buildLabel(label, prefix, suffix)}
    </span>

    {acronym &&
      <span className="select-input__option__acronym">{acronym}</span>
    }
  </components.Option>
)

export default Option

import React from "react"

import FormField from "components/form_field"
import { useFormFieldId } from "javascripts/lib/form"

const TextAreaField = ({
  label,
  name,
  error,
  hint,
  additionalHintText,
  ...props
}) => {
  const id = useFormFieldId(name)

  return (
    <FormField {...{ id, label, error, hint, additionalHintText }}>
      <textarea
        className="form__input"
        type="text"
        id={id}
        name={name}
        rows={1}
        data-controller="autosized-textarea"
        {...props}
      />
    </FormField>
  )
}

export default TextAreaField

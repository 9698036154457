import React from "react"
import prettyBytes from "pretty-bytes"

const AssetPreview = ({ url, filename, fileSize, children }) => (
  <div className="asset-preview">
    <div className="asset-preview__thumb">
      <img src={url} alt="" />
    </div>

    <div className="asset-preview__content">
      {filename &&
        <h6 className="asset-preview__filename">
          {filename}
          {fileSize &&
            <span className="asset-preview__file-size">{prettyBytes(fileSize)}</span>
          }
        </h6>
      }
      {children}
    </div>
  </div>
)

export default AssetPreview

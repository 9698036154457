import React from "react"
import ReactComponentController from "javascripts/lib/react_component_controller"

import TaskProfile from "components/task_profile/task_profile"

export default class extends ReactComponentController {
  get component() {
    return (
      <TaskProfile {...this.props} />
    )
  }
}

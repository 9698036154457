import React from "react"
import ReactComponentController from "javascripts/lib/react_component_controller"

import CardProfile from "components/card_profile/card_profile"

export default class extends ReactComponentController {
  get component() {
    return (
      <CardProfile {...this.props} />
    )
  }
}

import React, { useState, useRef } from "react";
import classNames from "classnames";
import scrollIntoView from "scroll-into-view-if-needed";

import TextButton from "components/text_button";

const EditableArea = ({
  attributes,
  handleSave,
  handleSuccess,
  handleDelete,
  ShowComponent,
  FormComponent,
  fitted = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const containerRef = useRef(null);

  const switchtoShow = () => {
    setIsEditing(false);

    containerRef.current.focus();
    scrollIntoView(containerRef.current, {
      scrollMode: "if-needed",
      block: "nearest",
      inline: "nearest",
    });
  };

  const switchToEdit = () => {
    setIsEditing(true);
  };

  const handleFormSuccess = (result) => {
    handleSuccess(result);
    switchtoShow();
  };

  const handleKeyPress = (event) => {
    if (
      event.key === "Enter" &&
      event.target &&
      event.target.classList.contains("editable-area")
    ) {
      switchToEdit();
    }
  };

  const className = classNames("editable-area", {
    "editable-area--editing": isEditing,
    "editable-area--fitted": fitted,
  });

  return (
    <div
      className={className}
      tabIndex="0"
      onKeyPress={!isEditing ? handleKeyPress : null}
      onDoubleClick={!isEditing ? switchToEdit : null}
      title={!isEditing ? "Double-click to edit" : null}
      ref={containerRef}
    >
      {isEditing ? (
        <FormComponent
          attributes={attributes}
          handleSave={handleSave}
          handleSuccess={handleFormSuccess}
          handleCancel={switchtoShow}
        />
      ) : (
        <>
          <div className="editable-area__content">
            <ShowComponent
              switchToEdit={switchToEdit}
              handleSuccess={handleSuccess}
              {...attributes}
            />
          </div>
          <ul className="editable-area__actions">
            <li className="editable-area__action">
              <TextButton
                label="Edit"
                icon="edit"
                tabIndex="-1"
                onClick={switchToEdit}
              />
            </li>
            {handleDelete && (
              <li className="editable-area__action">
                <TextButton
                  className="form__cancel"
                  label="Delete"
                  icon="delete"
                  onClick={handleDelete}
                />
              </li>
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default EditableArea;

import React from 'react'

const Figure = ({ wrongLetters }) => {
  const errors = wrongLetters.length

  return (
    <svg height="300" width="200" className="figure-container">
      {/* <!-- Rod --> */}
      {/* <line x1="60" y1="20" x2="140" y2="20" />
      <line x1="140" y1="20" x2="140" y2="50" />
      <line x1="60" y1="20" x2="60" y2="230" />
      <line x1="20" y1="230" x2="100" y2="230" /> */}
      <line x1="70" y1="60" x2="100" y2="20" />
      <line x2="5" y1="60" x1="35" y2="20" />

      {errors > 0 && <circle cx="50" cy="70"  r="18" />}
      {errors > 1 && <circle cx="50" cy="110" r="18" />}
      {errors > 2 && <circle cx="50" cy="150" r="18" />}
      {errors > 3 && <circle cx="50" cy="190" r="18" />}
      {errors > 4 && <circle cx="50" cy="230" r="18" />}
      {errors > 5 && <circle cx="50" cy="270" r="18" />}
    </svg>
  );
}

export default Figure

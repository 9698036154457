import React, { useContext } from "react";

import AttributeList from "components/attribute_list";
import CallToAction from "components/call_to_action";
import Form from "components/editable_area/components/form";
import TextAreaField from "components/editable_area/components/text_area_field";
import SelectField from "components/editable_area/components/select_field";
import FormOptionsContext from "contexts/form_options_context";
import Link from "components/external_link";

const ALLOWED_ATTRIBUTES = ["question", "front", "answer", "tags"];

export const Details = ({ question, front, answer, tags, switchToEdit }) => (
  <div>
    <AttributeList
      inlineLabels
      attributes={[
        { label: "Question", value: question },
        { label: "Hint", value: front },
        { label: "Answer", value: answer },
      ]}
    />
    <AttributeList
      inlineLabels
      attributes={[
        {
          label: "",
          value: tags
            ? tags.map((tag) => (
                <Link
                  href={`/cards?tag_search=${encodeURIComponent(tag.label)}`}
                >
                  {tag.label}
                </Link>
              ))
            : "",
        },
      ]}
    />
    <CallToAction
      className="editable-area__cta"
      handleClick={switchToEdit}
      actions={{
        question: question,
      }}
    />
  </div>
);

export const DetailsForm = (props) => {
  const { tags } = useContext(FormOptionsContext);

  return (
    <Form allowedAttributes={ALLOWED_ATTRIBUTES} {...props}>
      <p>All fields are required</p>
      <TextAreaField name="question" label="Question" />
      <TextAreaField name="front" label="Hint" />
      <TextAreaField name="answer" label="Answer" />
      <SelectField name="tags" label="Tags" options={tags} isMulti={true} />
      <Link href="/tags/new">Add new tag</Link>
    </Form>
  );
};

import queryString from "query-string"

// Custom RequestError class
// Code from: https://stackoverflow.com/posts/35858868/revisions
class RequestError extends Error {
  constructor(message) {
    super()

    this.name = "RequestError"
    this.message = message
    this.stack = new Error().stack
  }
}

const throwRequestFailure = response => {
  if (!response.ok) {
    const error = new RequestError(response.statusText)

    if (window.bugsnagClient) {
      window.bugsnagClient.notify(error)
    }

    throw error
  }

  return response
}

const csrfToken = () => {
  const param = document.head.querySelector("[name=csrf-param]")
  const token = document.head.querySelector("[name=csrf-token]")

  if (param && token) {
    return { [param.content]: token.content }
  }
}

const urlWithQueryString = (url, data) => {
  if (data) {
    return `${url}?${queryString.stringify(data, { arrayFormat: "bracket" })}`
  } else {
    return url
  }
}

const toFormData = fields => {
  const formData = new FormData()

  Object
    .entries(fields)
    .forEach(([field, value]) => formData.append(field, value))

  return formData
}

export const get = (url, data) => {
  return fetch(urlWithQueryString(url, data), {
    credentials: "include",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    }
  }).then(throwRequestFailure)
}

export const getHtml = (url, data) => {
  return fetch(urlWithQueryString(url, data), {
    credentials: "include",
    redirect: "manual" // allow throwRequestFailure to bail out if a redirect is going to occur
  }).then(throwRequestFailure)
}

const sendFormData = (method, url, data) =>
  fetch(url, {
    method,
    credentials: "include",
    body: toFormData({
      ...csrfToken(),
      ...data
    })
  }).then(throwRequestFailure)

const request = (method, url, data) =>
  fetch(url, {
    method,
    credentials: "include",
    body: JSON.stringify({
      ...csrfToken(),
      ...data
    }),
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    }
  }).then(throwRequestFailure)

export const post = (url, data) => request("POST", url, data)
export const put = (url, data) => request("PUT", url, data)
export const del = (url) => request("DELETE", url)

export const postFormData = (url, data) => sendFormData("POST", url, data)
export const putFormData = (url, data) => sendFormData("PUT", url, data)

export const handleFailedRequest = (handler) => {
  return (error) => {
    if (error instanceof RequestError) {
      handler(error)
    } else {
      throw error
    }
  }
}

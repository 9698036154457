import React from "react"
import { connect, getIn } from "formik"

import TextAreaField from "components/text_area_field"

const EditableAreaTextAreaField = ({ formik, ...props }) => {
  const value = getIn(formik.values, props.name)
  const error = getIn(formik.errors, props.name)

  const { handleChange, handleBlur, isSubmitting } = formik

  return (
    <TextAreaField
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      error={error}
      disabled={isSubmitting}
      {...props}
    />
  )
}

export default connect(EditableAreaTextAreaField)

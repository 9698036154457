import React from "react"
import classNames from "classnames"

import FormField from "components/form_field"
import SelectInput from "components/select_input/unmanaged"

const SelectField = ({
  label,
  name,
  error,
  hint,
  additionalHintText,
  halfSize = false,
  ...props
}) => {
  const selectClassName = classNames({ "form__input--half": halfSize })

  return (
    <FormField {...{ error, hint, additionalHintText }}>
      <SelectInput name={name} label={label} className={selectClassName} {...props} />
    </FormField>
  )
}

export default SelectField

import React, { useState, useEffect } from "react";
import Header from "./components/header";
import Figure from "./components/figure";
import WrongLetters from "./components/wrong_letters";
import Word from "./components/word";
import Popup from "./components/popup";
import Notification from "./components/notification";
import { showNotification as show } from "./components/helpers";

function App(props) {
  const [playable, setPlayable] = useState(true);
  const [correctLetters, setCorrectLetters] = useState([]);
  const [wrongLetters, setWrongLetters] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const rawSelectedWord = props.answer.toLowerCase();
  const selectedWord = props.answer.toLowerCase().replace(/\s+/g, "");

  useEffect(() => {
    const handleKeydown = (event) => {
      const { key, keyCode } = event;
      if (
        (playable && keyCode >= 48 && keyCode <= 90) ||
        keyCode == 188 ||
        keyCode == 190 ||
        keyCode == 222
      ) {
        const letter = key.toLowerCase();
        if (selectedWord.includes(letter)) {
          if (!correctLetters.includes(letter)) {
            setCorrectLetters((currentLetters) => [...currentLetters, letter]);
          } else {
            show(setShowNotification);
          }
        } else {
          if (!wrongLetters.includes(letter)) {
            setWrongLetters((currentLetters) => [...currentLetters, letter]);
          } else {
            show(setShowNotification);
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeydown);

    return () => window.removeEventListener("keydown", handleKeydown);
  }, [correctLetters, wrongLetters, playable]);

  function playAgain() {
    setPlayable(true);

    // Empty Arrays
    setCorrectLetters([]);
    setWrongLetters([]);
  }

  return (
    <>

      <Header props={props} />
      <div className="game-container">
        <Figure wrongLetters={wrongLetters} />
        <WrongLetters wrongLetters={wrongLetters} />
        <Word
          rawSelectedWord={rawSelectedWord}
          selectedWord={selectedWord}
          correctLetters={correctLetters}
        />
      </div>
      <Popup
        correctLetters={correctLetters}
        wrongLetters={wrongLetters}
        rawSelectedWord={rawSelectedWord}
        selectedWord={selectedWord}
        setPlayable={setPlayable}
        playAgain={playAgain}
        props={props}
      />
    </>
  );
}

export default App;

import React from "react"

const toSentence = actions => {
  if (actions.length > 1) {
    actions[actions.length - 1] = `and ${actions[actions.length - 1]}`
  }

  return actions.join(", ")
}

const CallToAction = ({ handleClick, className, actions = {} }) => {
  const availableActions =
    Object.entries(actions)
      .filter(([_key, value]) => !(value === 0 || value))
      .map(([key]) => key)

  if (availableActions.length === 0) {
    return null
  }

  const onClick = event => {
    event.preventDefault()
    handleClick()
  }

  return (
    <p className={className}>
      <a href="#" onClick={onClick}>
        Add {toSentence(availableActions)}
      </a>
    </p>
  )
}

export default CallToAction

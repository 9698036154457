import React from "react";
import ReactComponentController from "javascripts/lib/react_component_controller";

import Snowman from "components/snowman/snowman";

export default class extends ReactComponentController {
  get tags() {
    return this.data.get("tags");
  }

  get component() {
    return <Snowman tags={this.tags} {...this.props} />;
  }
}

import React from "react"

import FormField from "components/form_field"
import { useFormFieldId } from "javascripts/lib/form"

const FileField = ({
  label,
  name,
  error,
  hint,
  additionalHintText,
  ...props
}) => {
  const id = useFormFieldId(name)

  return (
    <FormField {...{ id, label, error, hint, additionalHintText }}>
      <input
        type="file"
        id={id}
        name={name}
        {...props}
      />
    </FormField>
  )
}

export default FileField

import React from "react"
import { connect, getIn } from "formik"

import TextField from "components/text_field"

const EditableAreaTextField = ({ formik, ...props }) => {
  const value = getIn(formik.values, props.name)
  const error = getIn(formik.errors, props.name)

  const { handleChange, handleBlur, isSubmitting } = formik

  return (
    <TextField
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      error={error}
      disabled={isSubmitting}
      {...props}
    />
  )
}

export default connect(EditableAreaTextField)

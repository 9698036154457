import React, { useReducer } from "react"

import FormOptionsContext from "contexts/form_options_context"
import reducer from "./reducers/item_reducer"

const EditablePage = ({
  item: { id, ...initialState },
  formOptions,
  RegionsComponent,
  ...props
}) => {
  const [attributes, dispatch] = useReducer(reducer, initialState)

  const handleSuccess = ({ values, response: { success: _success, ...responseValues } }) => {

    dispatch({
      type: "UPDATE_ATTRIBUTES", payload: {
        ...values,
        ...responseValues
      }
    })
  }

  return (
    <FormOptionsContext.Provider value={formOptions}>
      <RegionsComponent
        id={id}
        item={attributes}
        handleSuccess={handleSuccess}
        dispatch={dispatch}
        {...props}
      />
    </FormOptionsContext.Provider>
  )
}

export default EditablePage

import React, { useEffect } from "react";
import { checkWin } from "../components/helpers";

const Popup = ({
  correctLetters,
  wrongLetters,
  rawSelectedWord,
  selectedWord,
  setPlayable,
  playAgain,
  props,
}) => {
  let finalMessage = "";
  let finalMessageRevealWord = "";
  let playable = true;
  let correct = false;

  if (checkWin(correctLetters, wrongLetters, selectedWord) === "win") {
    finalMessage = "Congratulations! You are correct.";
    playable = false;
    correct = true;
  } else if (checkWin(correctLetters, wrongLetters, selectedWord) === "lose") {
    finalMessage = "Unfortunately, that is wrong.";
    finalMessageRevealWord = `...the answer is ${rawSelectedWord}`;
    playable = false;
  }

  useEffect(() => {
    setPlayable(playable);
  });

  return (
    <div
      className="popup-container"
      style={finalMessage !== "" ? { display: "flex" } : {}}
    >
      <div className="popup">
        <h2>{finalMessage}</h2>
        <p>{finalMessageRevealWord}</p>
        {correct ? (
          <a
            ref={(link) => link && link.focus()}
            href={`/cards/practice?id=${props.id}&tag_ids=${props.tags}`}
          >
            Next
          </a>
        ) : (
          <button ref={(link) => link && link.focus()} onClick={playAgain}>
            Try again
          </button>
        )}
      </div>
    </div>
  );
};

export default Popup;

import { useMemo } from "react"
import uniqid from "uniqid"

const ERROR_CLASS = "form__error"
const ERROR_SELECTOR = `.${ERROR_CLASS}`

const FIELD_SELECTOR = ".form__field"

const FIELD_ERROR_CLASS = "form__field--error"
const FIELD_ERROR_SELECTOR = `.${FIELD_ERROR_CLASS}`

const INPUT_SELECTOR = "input[type=text], input[type=checkbox], input[type=radio], input[type=number], textarea, select"
const INPUT_SELECTOR_INCLUDING_HIDDEN = `${INPUT_SELECTOR}, input[type=hidden]`

const findInput = (form, name) => form.querySelector(`[name*='[${name}]']:not([type=hidden])`)
const isRadio = (input) => input.type == "radio"
const isCheckbox = (input) => input.type == "checkbox"

const markAsChecked = (form, name, value) => {
  const input = form.querySelector(`[name*='[${name}]'][value='${value}']`)

  if (input) {
    input.checked = true
  }
}

const clearFormErrors = (form) => {
  form.querySelectorAll(FIELD_ERROR_SELECTOR)
    .forEach(field => { field.classList.remove(FIELD_ERROR_CLASS) })

  form.querySelectorAll(ERROR_SELECTOR).forEach(error => { error.remove() })
}

const applyAttributesToElement = (element, attributes) => {
  if (!attributes) return

  Object.entries(attributes).forEach(([attribute, value]) => {
    if (attribute == "data") {
      Object.entries(value).forEach(([dataAttribute, dataValue]) => {
        element.setAttribute(`data-${dataAttribute}`, dataValue)
      })
    } else {
      element.setAttribute(attribute, value)
    }
  })
}

export const applyFormErrors = (form, errors) => {
  clearFormErrors(form)

  Object.entries(errors).forEach(([field, fieldErrors]) => {
    const input = findInput(form, field)
    const wrapper = input.closest(FIELD_SELECTOR)

    wrapper.classList.add(FIELD_ERROR_CLASS)

    fieldErrors.forEach(errorMessge => {
      const error = document.createElement("p")
      error.textContent = errorMessge
      error.classList.add(ERROR_CLASS)

      wrapper.appendChild(error)
    })
  })
}

export const focusFirstInput = (form) => {
  form.querySelector(INPUT_SELECTOR).focus()
}

export const populateForm = (form, formData) => {
  Object.entries(formData).forEach(([field, value]) => {
    const input = findInput(form, field)

    if (!input) {
      return
    }

    if (isRadio(input) || isCheckbox(input)) {
      markAsChecked(form, field, value)
    } else {
      input.value = value
    }
  })
}

export const enableInput = (input) => input.disabled = false
export const disableInput = (input) => input.disabled = true

export const enableInputs = (container) => {
  container.querySelectorAll(INPUT_SELECTOR_INCLUDING_HIDDEN)
    .forEach(enableInput)
}

export const disableInputs = (container) => {
  container.querySelectorAll(INPUT_SELECTOR_INCLUDING_HIDDEN)
    .forEach(disableInput)
}

export const setSelectOptions = (selectOrOptionGroup, options, includeBlankOption) => {
  selectOrOptionGroup.innerHTML = "" // Clear out any existing options

  if (includeBlankOption) {
    selectOrOptionGroup.appendChild(new Option("", "", false, false))
  }

  options.forEach(([label, value, attributes]) => {
    const option = new Option(label, value, false, false)

    applyAttributesToElement(option, attributes)

    selectOrOptionGroup.appendChild(option)
  })
}

export const setSelectOptionGroups = (select, optionGroups) => {
  select.innerHTML = "" // Clear out any existing options

  optionGroups.forEach(([label, options]) => {
    const optGroup = document.createElement("optgroup")
    optGroup.label = label
    setSelectOptions(optGroup, options)

    select.appendChild(optGroup)
  })
}

export const setSelectValue = (select, value) => {
  const option = select.querySelector(`option[value='${value}']`)

  if (option) {
    option.selected = true
  }
}

export const useFormFieldId = fieldName => useMemo(() => `${fieldName}_${uniqid.time()}`, [fieldName])

import React, { useState } from "react"
import { connect, getIn } from "formik"

import AssetPreview from "components/asset_preview"
import FileField from "components/file_field"

const EditableAreaImageField = ({ formik, initialUrl, ...props }) => {
  const [url, setUrl] = useState(initialUrl)

  const error = getIn(formik.errors, props.name)
  const { setFieldValue, handleBlur, isSubmitting } = formik

  const onChange = event => {
    const file = event.target.files[0]

    setFieldValue(props.name, event.target.files[0])
    setUrl(URL.createObjectURL(file))
  }

  const field = (
    <FileField
      onChange={onChange}
      onBlur={handleBlur}
      error={error}
      disabled={isSubmitting}
      {...props}
    />
  )

  if (url) {
    return (
      <div className="form__field">
        <AssetPreview url={url}>
          {field}
        </AssetPreview>
      </div>
    )
  } else {
    return field
  }
}

export default connect(EditableAreaImageField)

import React from "react"

import PageRegion from "components/page_region"
import EditableArea from "components/editable_area"
import EditableCollection from "components/editable_area/components/collection"
import TaskSupportingResource from "javascripts/api/task_supporting_resource"

import Task from "javascripts/api/task"

import EditablePage from "components/editable_page"

import { Details, DetailsForm } from "./components/details"
import { SupportingResource, SupportingResourceForm } from "./components/supporting_resource"

const Regions = ({
  id,
  handleSuccess,
  dispatch,
  item: task
}) => {

  const handleSave = attributes => Task.update(id, attributes)

  const supportingResourceApi = TaskSupportingResource(id)

  return (
    <>
      <PageRegion heading="">
        <EditableArea
          attributes={task}
          ShowComponent={Details}
          FormComponent={DetailsForm}
          handleSave={handleSave}
          handleSuccess={handleSuccess}
        />
      </PageRegion>

      <PageRegion heading="Image gallery">
        <EditableCollection
          addButtonLabel="Add an image"
          items={task.supportingResources}
          api={supportingResourceApi}
          dispatch={args => dispatch({ ...args, scope: "supportingResources" })}
          ShowComponent={SupportingResource}
          FormComponent={SupportingResourceForm}
          confirmDeleteMessage="Are you sure you want to delete this image?"
        />
      </PageRegion>
    </>
  )
}

const TaskProfile = ({ task, ...props }) => <EditablePage RegionsComponent={Regions} item={task} {...props} />

export default TaskProfile

import React from "react"
import { Formik } from "formik"
import { isArray, isPlainObject, fromPairs } from "lodash"

import OutlinedButton from "components/outlined_button"
import RaisedButton from "components/raised_button"

const prepareValues = values => fromPairs(Object.entries(values).map(([key, value]) => {
  if (isArray(value)) {
    return [key, value.map(({ value }) => value)]
  } else if (isPlainObject(value)) {
    return [key, value.value]
  } else {
    return [key, value]
  }
}))

const Form = ({
  attributes = {},
  allowedAttributes = [],
  handleSave,
  handleSuccess,
  handleCancel,
  children
}) => {
  const initialValues = allowedAttributes.reduce((object, allowedAttribute) => {
    const value = attributes[allowedAttribute]
    object[allowedAttribute] = value === null || value === undefined ? "" : value

    return object
  }, {})

  const onSubmit = (values, { setErrors, setSubmitting }) => {
    handleSave(prepareValues(values))
      .then(response => {
        if (response.success) {
          handleSuccess({ values, response })
        } else {
          setSubmitting(false)
          setErrors(response.errors)
        }
      })
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting, errors = {} }) => (
        <form className="editable-area__content" onSubmit={handleSubmit}>
          {errors.base &&
            <p className="form__error form__error--no-field">{errors.base}</p>
          }

          {children}

          <div className="form__actions">
            <RaisedButton label={isSubmitting ? "Saving..." : "Save"} type="submit" disabled={isSubmitting} />
            <OutlinedButton label="Cancel" type="button" className="form__cancel" onClick={handleCancel} disabled={isSubmitting} />
          </div>
        </form>
      )}
    </Formik>
  )
}

export default Form

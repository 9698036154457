import React from 'react'

const Word = ({ rawSelectedWord, selectedWord, correctLetters }) => {

  return (
    <div className="word">
      {rawSelectedWord.split('').map((letter, i) => {
        return (
          <span className={letter == " " ? "gap" : "letter"} key={i}>
            {correctLetters.includes(letter) ? letter : ''}
          </span>
        )
      })}
    </div>
  )
}

export default Word

/* eslint-disable no-unused-vars */
/* global Grid:readonly, process:readonly */

import "../stylesheets/application.scss";

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

// Initialise Stimulus

const application = Application.start();
const context = require.context("../controllers", true, /\.js$/);

const definitions = definitionsFromContext(context);

application.load([].concat(definitions));

require("@rails/ujs").start();
// require("@rails/activestorage").start()
// require("trix")
// require("@rails/actiontext")

import React from "react"
import { connect, getIn } from "formik"

import SelectField from "components/select_field"

const EditableAreaSelectField = ({ formik, ...props }) => {
  const value = getIn(formik.values, props.name)
  const error = getIn(formik.errors, props.name)

  const { setFieldValue, isSubmitting } = formik

  return (
    <SelectField
      onChange={selectedValue => setFieldValue(props.name, selectedValue)}
      value={value}
      error={error}
      isDisabled={isSubmitting}
      {...props}
    />
  )
}

export default connect(EditableAreaSelectField)

import React from "react"
import classNames from "classnames"

// import Hint from "components/hint"

const FormField = ({
  id,
  label,
  error,
  hint,
  additionalHintText,
  isGroup = false,
  children
}) => {
  const className = classNames("form__field", {
    "form__field-group": isGroup,
    "form__field--error": error
  })

  return (
    <div className={className}>
      {label &&
        <label className={isGroup ? "form__field-group__label" : "form__label"} htmlFor={id}>{label}</label>
      }
      {children}
      {/* <Hint text={hint}>{additionalHintText}</Hint> */}
      {error && <p className="form__error">{error}</p>}
    </div>
  )
}

export default FormField

import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin"
import chrono from "chrono-node"

function flatpickrOptions() {
  return {
    allowInput: true,
    altInput: true,
    altFormat: "d M Y",
    altInputClass: "form__input form__input--date",
    parseDate: dateString => chrono.parseDate(dateString),
    plugins: [new labelPlugin()],
    errorHandler: error => {
      if (window.bugsnagClient) {
        window.bugsnagClient.notify(error)
      } else {
        // eslint-disable-next-line no-console
        return typeof console !== "undefined" && console.warn(error)
      }
    }
  }
}

export default flatpickrOptions

import React, { useState, useEffect, useCallback, useContext } from "react"
import { get } from "lodash"

import AssetPreview from "components/asset_preview"
import AttributeList from "components/attribute_list"
import Form from "components/editable_area/components/form"
import ImageField from "components/editable_area/components/image_field"
import SelectField from "components/editable_area/components/select_field"
import CardSupportingResource from "javascripts/api/card_supporting_resource"
import FormOptionsContext from "contexts/form_options_context"

const MAX_ATTEMPTS = 5
const CHECK_PROCESSING_DELAY = 2500

const ALLOWED_ATTRIBUTES = [
  "image",
  "status"
]

const ProcessingMessage = ({ attempt, attemptFailed, setAttempt }) => {
  let message

  if (attemptFailed) {
    message = (
      <>
        Checking image status has failed.
        {" "}
        <a href="">Reload page</a>
      </>
    )
  } else if (attempt === MAX_ATTEMPTS) {
    const resetAttempts = event => {
      event.preventDefault()

      setAttempt(0)
    }

    message = (
      <>
        Image has not finished processing.
        {" "}
        <a href="#" onClick={resetAttempts}>Check again</a>
      </>
    )
  } else {
    message = "Waiting for image to process..."
  }

  return (
    <p className="asset-preview__message">{message}</p>
  )
}

export const SupportingResource = ({
  id,
  resourceableId,
  url,
  filename,
  fileSize,
  isProcessing,
  status,
  handleSuccess
}) => {
  const [attempt, setAttempt] = useState(0)
  const [attemptFailed, setAttemptFailed] = useState(false)

  const checkProcessingStatus = useCallback(attempt => {
    CardSupportingResource(resourceableId).show(id)
      .then(values => {
        if (values.isProcessing) {
          setAttempt(attempt + 1)
        } else {
          handleSuccess({ values })
        }
      })
      .catch(() => setAttemptFailed(true))
  }, [id, resourceableId, handleSuccess])

  useEffect(() => {
    if (!isProcessing || attempt === MAX_ATTEMPTS || attemptFailed) {
      return
    }

    const timeout = setTimeout(() => {
      checkProcessingStatus(attempt)
    }, CHECK_PROCESSING_DELAY * (attempt + 1))

    return () => clearTimeout(timeout)
  }, [attempt, attemptFailed, isProcessing, checkProcessingStatus])

  return (
    <AssetPreview url={url} filename={filename} fileSize={fileSize}>
      {isProcessing &&
        <ProcessingMessage
          attempt={attempt}
          attemptFailed={attemptFailed}
          setAttempt={setAttempt}
        />
      }

      <AttributeList inlineLabels attributes={[
        { label: "Belongs to", value: status },
      ]} />
    </AssetPreview>
  )
}

export const SupportingResourceForm = props => {
  const url = get(props, ["attributes", "url"])
  const { supportingResourceStatus } = useContext(FormOptionsContext)

  const defaultAttributes = {
    status: supportingResourceStatus[0],
  }

  return (
    <Form allowedAttributes={ALLOWED_ATTRIBUTES} attributes={defaultAttributes} {...props}>
      <ImageField name="image" label="File" initialUrl={url} />
      <SelectField
        name="status"
        label="Question or answer?"
        options={supportingResourceStatus}
        isClearable={false}
        halfSize
      />
    </Form>
  )
}

import React from "react"
import classnames from "classnames"
import { isArray, compact } from "lodash"

const AttributeValue = ({ value }) => {
  if (!isArray(value)) {
    return value
  }

  return(
    <ul className="attribute-list__items">
      {compact(value).map((val, index) =>
        <li key={index} className="attribute-list__sub-item">{val}</li>
      )}
    </ul>
  )
}

const valueIsPresent = value => {
  if (isArray(value)) {
    return compact(value).length > 0
  }

  return value === 0 || value
}

const AttributeList = ({ attributes, inlineLabels }) => {
  const presentAttributes = attributes.filter(({ value }) => valueIsPresent(value))

  if (presentAttributes.length === 0) {
    return null
  }

  const className = classnames("attribute-list", "attribute-list--compact", {
    "attribute-list--inline-labels": inlineLabels
  })

  return (
    <div className={className}>
      <ul className="attribute-list__items">
        {presentAttributes.map(({ label, value, multiline = false }, index) =>
          <li key={label || index} className="attribute-list__item">
            {label &&
              <h5 className="attribute-list__label">{label}</h5>
            }
            <div className={classnames("attribute-list__value", {
              "attribute-list__value--multiline": multiline
            })}>
              <AttributeValue value={value} />
            </div>
          </li>
        )}
      </ul>
    </div>
  )
}

export default AttributeList

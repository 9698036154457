import React, { useContext } from "react";

import AttributeList from "components/attribute_list";
import CallToAction from "components/call_to_action";
import Form from "components/editable_area/components/form";
import TextField from "components/editable_area/components/text_field";
import DateField from "components/editable_area/components/date_field";
import TextAreaField from "components/editable_area/components/text_area_field";
import SelectField from "components/editable_area/components/select_field";
import FormOptionsContext from "contexts/form_options_context";
import Link from "components/external_link";
import moment from "moment";

const ALLOWED_ATTRIBUTES = [
  "name",
  "due",
  "description",
  "content",
  "notes",
  "parentComments",
  "tags",
];

export const Details = ({
  name,
  due,
  description,
  content,
  notes,
  parentComments,
  tags,
  switchToEdit,
}) => (
  <div>
    <h1>{name}</h1>
    <span className="metadata">{moment(due).format("ll")}</span>

    <AttributeList inlineLabels attributes={[{ label: "", value: content }]} />
    <hr />
    <AttributeList
      attributes={[
        { label: "Notes ", value: notes },
        { label: "Learning comments", value: parentComments },
      ]}
    />
    <AttributeList
      inlineLabels
      attributes={[
        {
          label: "",
          value: tags
            ? tags.map((tag) => (
                <Link
                  href={`/tasks?tag_search=${encodeURIComponent(tag.label)}`}
                >
                  {tag.label}
                </Link>
              ))
            : "",
        },
      ]}
    />
    <CallToAction
      className="editable-area__cta"
      handleClick={switchToEdit}
      actions={{
        name: name,
      }}
    />
  </div>
);

export const DetailsForm = (props) => {
  const { tags } = useContext(FormOptionsContext);

  return (
    <Form allowedAttributes={ALLOWED_ATTRIBUTES} {...props}>
      <DateField name="due" label="Due" />
      <TextField name="name" label="Name" autoFocus />
      <TextAreaField name="content" label="Instructions" />
      <hr />
      <p>To be completed by the educator.</p>
      <TextAreaField
        name="notes"
        label="How was it? (This marks the task as 'done')"
      />
      <TextAreaField name="parentComments" label="Learning comments" />
      <SelectField name="tags" label="Tags" options={tags} isMulti={true} />
      <Link href="/tags/new">Add new tag</Link>
    </Form>
  );
};

import React from "react";

import PageRegion from "components/page_region";
import EditableArea from "components/editable_area";
import EditableCollection from "components/editable_area/components/collection";
import CardSupportingResource from "javascripts/api/card_supporting_resource";

import Card from "javascripts/api/card";

import EditablePage from "components/editable_page";

import { Details, DetailsForm } from "./components/details";
import {
  SupportingResource,
  SupportingResourceForm,
} from "./components/supporting_resource";

const Regions = ({ id, handleSuccess, dispatch, item: card }) => {
  const handleSave = (attributes) => Card.update(id, attributes);

  const supportingResourceApi = CardSupportingResource(id);

  return (
    <>
      <PageRegion heading="">
        <EditableArea
          attributes={card}
          ShowComponent={Details}
          FormComponent={DetailsForm}
          handleSave={handleSave}
          handleSuccess={handleSuccess}
        />
      </PageRegion>
      <a className="duplicate" href={`/cards/${id}/duplicate`}>
        Create another with this tag
      </a>

      <PageRegion heading="Images">
        <EditableCollection
          addButtonLabel="Add an image"
          items={card.supportingResources}
          api={supportingResourceApi}
          dispatch={(args) =>
            dispatch({ ...args, scope: "supportingResources" })
          }
          ShowComponent={SupportingResource}
          FormComponent={SupportingResourceForm}
          confirmDeleteMessage="Are you sure you want to delete this image?"
        />
      </PageRegion>
    </>
  );
};

const CardProfile = ({ card, ...props }) => (
  <EditablePage RegionsComponent={Regions} item={card} {...props} />
);

export default CardProfile;

import React from "react"
import classnames from "classnames"

const OutlinedButton = ({ label, icon, small, className, ...props }, ref) => (
  <button ref={ref} className={classnames("outlined-button", className, { "outlined-button--small": small })} {...props}>
    {icon && <i className={`zmdi zmdi-${icon}`}></i>}
    {label}
  </button>
)

export default React.forwardRef(OutlinedButton)

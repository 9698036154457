import { get, postFormData, putFormData, del } from "javascripts/lib/request"

export default id => ({
  show: supportingResourceId =>
    get(`/cards/${id}/card_supporting_resources/${supportingResourceId}`)
      .then(response => response.json()),

  create: supportingResource =>
    postFormData(`/cards/${id}/card_supporting_resources`, supportingResource)
      .then(response => response.json()),

  update: (supportingResourceId, supportingResource) =>
    putFormData(`/cards/${id}/card_supporting_resources/${supportingResourceId}`, supportingResource)
      .then(response => response.json()),

  destroy: supportingResourceId =>
    del(`/cards/${id}/card_supporting_resources/${supportingResourceId}`)
})

import React from "react"
import classNames from "classnames"

import FormField from "components/form_field"
import { useFormFieldId } from "javascripts/lib/form"

const TextField = ({
  label,
  name,
  error,
  hint,
  additionalHintText,
  halfSize = false,
  eighthSize = false,
  ...props
}) => {
  const id = useFormFieldId(name)

  const inputClassName = classNames("form__input", {
    "form__input--half": halfSize && !eighthSize,
    "form__input--eighth": eighthSize
  })

  return (
    <FormField {...{ id, label, error, hint, additionalHintText }}>
      <input
        className={inputClassName}
        type="text"
        id={id}
        name={name}
        {...props}
      />
    </FormField>
  )
}

export default TextField

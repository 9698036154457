import React, { useState, useRef, useEffect } from "react";

import EditableArea from "../";
import OutlinedButton from "components/outlined_button";

const Item = ({
  isEditable,
  attributes,
  handleSave,
  handleSuccess,
  handleDelete,
  ShowComponent,
  FormComponent,
}) => {
  if (!isEditable) {
    return <ShowComponent {...attributes} />;
  }

  return (
    <EditableArea
      attributes={attributes}
      handleSave={handleSave}
      handleSuccess={handleSuccess}
      handleDelete={handleDelete}
      ShowComponent={ShowComponent}
      FormComponent={FormComponent}
    />
  );
};

const Collection = ({
  items = [],
  addButtonLabel,
  api,
  dispatch,
  confirmDeleteMessage,
  isEditable = true,
  ShowComponent,
  FormComponent,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [idsBeingRemoved, setIdsBeingRemoved] = useState([]);

  const isInitialMount = useRef(true);
  const buttonRef = useRef(null);

  const availableItems = items.filter(
    ({ id }) => !idsBeingRemoved.includes(id)
  );
  const showList = availableItems.length > 0 || isAdding;

  const handleAdd = () => {
    setIsAdding(true);
  };

  const handleCancel = () => {
    setIsAdding(false);
  };

  const handleCreateSuccess = ({
    values,
    response: { id, ...responseValues },
  }) => {
    dispatch({
      type: "ADD_ITEM",
      payload: {
        id,
        ...values,
        ...responseValues,
      },
    });

    setIsAdding(false);
  };

  const handleUpdateSuccess =
    (id) =>
    ({ values, response: { id: _id, ...responseValues } = {} }) =>
      dispatch({
        type: "UPDATE_ITEM",
        id,
        payload: {
          ...values,
          ...responseValues,
        },
      });

  const handleDelete = (id) => () => {
    if (!window.confirm(confirmDeleteMessage)) {
      return;
    }

    setIdsBeingRemoved((ids) => [...ids, id]);

    api.destroy(id).then(() => {
      dispatch({
        type: "DELETE_ITEM",
        id,
      });
    });
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (!isAdding) {
      buttonRef.current.focus();
    }
  }, [isAdding]);

  return (
    <div className="editable-collection">
      {showList && (
        <ol className="editable-collection__list">
          {availableItems.map((item) => (
            <li className="editable-collection__item" key={item.id}>
              <Item
                isEditable={isEditable}
                attributes={item}
                handleSave={(values) => api.update(item.id, values)}
                handleSuccess={handleUpdateSuccess(item.id)}
                handleDelete={handleDelete(item.id)}
                ShowComponent={ShowComponent}
                FormComponent={FormComponent}
              />
            </li>
          ))}
          {isAdding && (
            <li className="editable-collection__item editable-collection__item--form">
              <FormComponent
                handleSave={api.create}
                handleCancel={handleCancel}
                handleSuccess={handleCreateSuccess}
              />
            </li>
          )}
        </ol>
      )}

      {!isAdding && (
        <div className="editable-collection__footer">
          <OutlinedButton
            ref={buttonRef}
            onClick={handleAdd}
            label={addButtonLabel}
            small
            icon="plus"
          />
        </div>
      )}
    </div>
  );
};

export default Collection;

import React from "react"
import classNames from "classnames"
import Flatpickr from "react-flatpickr"

import flatpickrOptions from "javascripts/lib/flatpickr_options"
import FormField from "components/form_field"
import { useFormFieldId } from "javascripts/lib/form"

const DateField = ({
  label,
  name,
  error,
  hint,
  additionalHintText,
  ...props
}) => {
  const id = useFormFieldId(name)
  const inputClassName = classNames("form__input", "form__input--date")

  return (
    <FormField {...{ id, label, error, hint, additionalHintText }}>
      <Flatpickr
        className={inputClassName}
        type="text"
        id={id}
        name={name}
        options={flatpickrOptions()}
        onClose={(_selectedDates, _dateStr, instance) => {
          if (instance.config.allowInput && instance._input.value && instance._input.value !== instance._selDateStr) {

            // sets the date in the datepicker to a proper date if the date is invalid e.g. "tomorrow"
            // second arg set to true to trigger onChange
            instance.setDate(instance._input.value, true)
          }
        }}
        {...props}
      />
    </FormField>
  )
}

export default DateField
